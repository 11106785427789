import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

// FOR EVENT Detail
import Blocks from "editorjs-blocks-react-renderer"
import { EventDetailTextTwo, BlogDetailTextOne } from "../components/typography"

const BlogDetail = ({ data, location }) => {
  const myData =
    data.strapiImpressum?.Content?.data?.childMarkdownRemark &&
    JSON.parse(
      data.strapiImpressum?.Content?.data?.childMarkdownRemark?.rawMarkdownBody
    )
  console.log(myData)
  return (
    <Layout
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      location={location}
      fullHeader
      title="Event Detail"
    >
      <div className="bg-white pb-20">
        <Seo title="Event detail" />
        <div className="h-96  bg-purplebglight-50 w-full" objectFit="cover" />
        <div
          className="relative  bg-white  rounded-2xl lg:rounded-4xl text-white text-center  -mt-12 max-w-7xl mx-auto
                 px-4
                 py-6
                 sm:px-20
                 sm:py-10
                 flex
                 flex-col
                 md:flex-row
                 flex-nowrap
                 "
        >
          <div
            className="flex flex-row justify-between md:flex-col md:justify-start md:mt-10 md:ml-10 md:order-2
              flex-nowrap
              justify-start
              items-center
              mb-8"
          ></div>
          <div className="mt-4 lg:mt-10 lg:pr-14 text-left text-black text-lg pb-10 w-11/12 font-inter">
            <BlogDetailTextOne additionalClassName="text-left text-fontpurple-50 mb-12 font-primary-bold text-3xl lg:text-[50px] lg:!leading-[56px]">
              House Rules
            </BlogDetailTextOne>
            <EventDetailTextTwo additionalClassName="text-left mb-12 font-primary-bold">
              {data.strapiBlog?.subtitle}
            </EventDetailTextTwo>
            <p>Na&scaron;a Ideja doo</p>
            <p>Damir T. Gavrana 7</p>
            <p>10000 Zagreb</p>
            <p>VAT ID: HR18299686975</p>
            <p>
              <br />
            </p>
            <p>
              Beach concessionaire NA&Scaron;A IDEJA doo, Zagreb, Damira
              Tomljanovića Gavrana 7, VAT ID: HR18299686975 in Zagreb on January
              1, 2023. bringing
            </p>
            <p>
              <br />
            </p>
            <p>RULES AND GENERAL CONDITIONS OF BEHAVIOR ON ZRĆE BEACH</p>
            <p>
              <br />
            </p>
            <p>
              Any form of use of the Zrće beach on the island of Pag and the
              associated sea area accepts these rules and general conditions of
              behavior on the same.
            </p>
            <p>
              <br />
            </p>
            <p>
              Guests, users and visitors of the beach are obliged to behave
              culturally and use the beach without disturbing other guests and
              visitors.
            </p>
            <p>
              <br />
            </p>
            <p>
              All guests and visitors to the beach must be appropriately dressed
              in a bathing suit or swimming trunks.
            </p>
            <p>
              <br />
            </p>
            <p>
              The use of balls and other props for the beach is allowed in a way
              that does not disturb other guests and visitors of the beach and
              in the space provided for that.
            </p>
            <p>
              <br />
            </p>
            <p>
              It is not allowed to use skateboards, bicycles, scooters,
              rollerblades, motorcycles and other means of transport and devices
              that would impair the safety of guests and beach visitors in the
              beach area.
            </p>
            <p>
              <br />
            </p>
            <p>
              Guests of the beach can rent sunbeds and umbrellas for an extra
              charge according to the valid Price List and depending on their
              availability.
            </p>
            <p>
              <br />
            </p>
            <p>
              Swimming behind the protective fence or jumping from the defensive
              structures at sea is not allowed for your safety and the safety of
              other guests and visitors to the beach.
            </p>
            <p>
              <br />
            </p>
            <p>
              The children's playground is permitted only under the supervision
              of adults and at your own risk.
            </p>
            <p>
              <br />
            </p>
            <p>
              For safety and health reasons, the following are not allowed on
              the beach and beach area:
            </p>
            <p>
              <br />
            </p>
            <p>
              - bringing in alcoholic or non-alcoholic beverages, especially in
              glass containers
            </p>
            <p>
              <br />
            </p>
            <p>
              - throwing cigarette butts and garbage outside baskets and
              containers
            </p>
            <p>
              <br />
            </p>
            <p>- lighting a fire and using gas stoves outdoors</p>
            <p>
              <br />
            </p>
            <p>- serving alcohol to persons under the age of 18</p>
            <p>
              <br />
            </p>
            <p>- entry of pets/animals</p>
            <p>
              <br />
            </p>
            <p>- camping or lighting a fire</p>
            <p>
              <br />
            </p>
            <p>
              - throwing and depositing garbage (garbage, cigarette butts, etc.)
              except in designated places
            </p>
            <p>
              <br />
            </p>
            <p>- urinating across the beach area</p>
            <p>
              <br />
            </p>
            <p>- sexual intercourse</p>
            <p>
              <br />
            </p>
            <p>- any pollution of the sea.</p>
            <p>
              <br />
            </p>
            <p>
              Suppose visitors or users of the beach behave contrary to these
              rules and the general conditions of behavior on the beach. In that
              case, they must pay a fine to the competent authorities on the
              beach in the amount prescribed by the valid Price List or another
              act in force.
            </p>
            <p>
              <br />
            </p>
            <p>
              On the beach and the beach area and the associated seawater area,
              it is not allowed to carry out any economic activities, i.e., the
              economical use without prior arrangement of mutual relations with
              the Beach Concessionaire NAŠA IDEJA doo, Zagreb, Damira
              Tomljanovića Gavrana 7, VAT ID: HR18299686975, and all by Art. 26
              of the Law on Maritime Property and Sea Ports.
            </p>
            <p>
              <br />
            </p>
            <p>
              For violation of these Rules and general conditions of behavior on
              the beach by carrying out any economic activity, i.e., economic
              use of them without prior arrangement of mutual relations with the
              Beach Concessionaire, Naša ideja doo has the right to charge the
              violator a contractual fine of EUR 5,000 (37.672,50 kn, Fiksni
              tečaj konverzije: 7,53450 kuna).
            </p>
            <p>
              <br />
            </p>
            <p>
              Violation of these Rules and general conditions of behavior on the
              beach and the associated swimming area in particular:
            </p>

            <p>
              <br />
            </p>
            <p>
              - distribution of leaflets, direct or indirect sales and similar
              actions without a valid contract with the concessionaire.
            </p>
            <p>
              <br />
            </p>
            <p>
              For violation of these Rules and general conditions of behavior on
              the beach by handing out flyers, direct or indirect sales and
              performing similar actions without a valid contract with the
              concessionaire, Naša ideja doo has the right to charge the
              violator a contractual fine o EUR 4,000 (37.672,50 kn, Fiksni
              tečaj konverzije: 7,53450 kuna).
            </p>
            <p>
              <br />
            </p>
            <p>
              - entering the bathing area with scooters, boats, windsurfing
              boards, pedal boats, etc., without the necessary consent of the
              concessionaire.
            </p>
            <p>
              <br />
            </p>
            <p>
              - traffic and parking of all types of motor vehicles without the
              necessary permission of the concessionaire
            </p>
            <p>
              <br />
            </p>
            <p>
              - installation of an ATM without the required approval of the
              concessionaire
            </p>
            <p>
              <br />
            </p>
            <p>
              - leaving personal belongings, especially towels, in the bathing
              area
            </p>
            <p>
              <br />
            </p>
            <p>
              - organizing any form of a public gathering of any kind without
              the necessary consent of the concessionaire
            </p>
            <p>
              <br />
            </p>
            <p>
              - construction of any type without the required permission of the
              concessionaire
            </p>
            <p>
              <br />
            </p>
            <p>
              - use heavy motor vehicles, trucks, cranes, and other construction
              machines to enter the area of Zrće beach, except for necessary
              repairs and emergency interventions, and with the prior consent of
              the concessionaire.
            </p>
            <p>
              <br />
            </p>
            <p>
              - to intervene on the border markings of the maritime property to
              tear down the marking of the border of the marine property
              established by a wall or fence and to open passageways on the
              same.
            </p>
            <p>
              <br />
            </p>
            <p>
              It is strictly forbidden to disturb public order and peace by
              unacceptable behavior, actions or activities on the beach and its
              immediate vicinity, i.e., parking lot, green areas, catering
              facilities and the like.
            </p>
            <p>
              <br />
            </p>
            <p>
              The concessionaire is not responsible for possible thefts,
              accidents or damage to things or persons, and visitors are
              recommended to insure their property or belongings.
            </p>
            <p>
              <br />
            </p>
            <p>
              During the event on the beach, guests and visitors are obliged to
              follow the special instructions of the concessionaire's employees
              on maintaining order on the part of the beach where the event is
              taking place.
            </p>
            <p>
              <br />
            </p>
            <p>
              Guests and visitors are obliged to inform the employees of the
              concessionaire in case of any perceived danger.
            </p>
            <p>
              <br />
            </p>
            <p>
              The concessionaire is not held responsible for thefts, damages or
              injuries in the concession area.
            </p>
            <p>
              <br />
            </p>
            <p>
              Suppose the concession holder and security service employees
              determine a violation of these rules by direct observation or
              complaints from other guests and visitors to the beach, they will
              warn the violators that in the event of continued breach of the
              rules, they will be removed from the beach, except in cases where
              the violator's behavior is not expected, that they will stop
              breaking the rules. Then the violators will be removed from the
              beach. In case of need, the concession holder will request the
              official action of the competent institutions.
            </p>
            <p>
              <br />
            </p>
            <p>In a dispute, the competent court in Zagreb has jurisdiction.</p>
            <p>
              <br />
            </p>
            <p>
              These General Terms and Conditions are published on the Company's
              notice board. All Users can download them in printed form from the
              Company's records.
            </p>
            <p>
              <br />
            </p>
            <p>
              These General Terms and Conditions come into force and apply from
              January 1, 2023.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetail

export const blogQuery = graphql`
  query ImpressumFirst {
    strapiImpressum {
      Content {
        data {
          childMarkdownRemark {
            id
            rawMarkdownBody
          }
        }
      }
    }
  }
`
